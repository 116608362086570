<template>
  <v-app>
    <div>
      <v-toolbar flat dense color="primary" :hidden="hiddenToolbar" class="hidden-print-only" dark>
        <v-btn icon text @click="$router.back()">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="d-none d-sm-inline">Airline Ticket -</span>
          {{profileName}}
        </v-toolbar-title>
        <div class="flex-grow-1"></div> 
        <v-toolbar-items>
          <v-btn text @click="changeRouter('bill')" v-if="uiAccept.moneyservice">
            <v-icon class="d-md-none">scatter_plot</v-icon>
            <span class="ml-2 d-none d-sm-flex">Money Service</span>
          </v-btn>
          <v-menu offset-y left v-if="uiAccept.newvoucher || uiAccept.voucherlist || uiAccept.voucherall || uiAccept.receivevoucher">
            <template v-slot:activator="{ on }">
              <v-btn text v-on="on" >
                <v-icon class="d-md-none">post_add</v-icon>
                <span class="ml-2 d-none d-sm-flex">Voucher</span>
                <v-icon class="d-none d-sm-flex">expand_more</v-icon>
              </v-btn>
            </template>
            <v-card class="mx-auto pr-1 pl-1" max-width="300" tile>
              <v-list dense>
                <v-list-item-group color="primary">
                  <v-list-item
                    v-for="(item, index) in order"
                    :key="index"
                    @click="changeRouter(item.value)"
                  >
                    <v-list-item-icon>
                      <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.title"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-menu>
          <v-menu offset-y left v-if="uiAccept.receipt||uiAccept.commission||uiAccept.payment">
            <template v-slot:activator="{ on }">
              <v-btn text v-on="on">
                <v-icon class="d-md-none">post_add</v-icon>
                <span class="ml-2 d-none d-sm-flex">Payment</span>
                <v-icon class="d-none d-sm-flex">expand_more</v-icon>
              </v-btn>
            </template>
            <v-card class="mx-auto pr-1 pl-1" max-width="300" tile>
              <v-list dense>
                <v-list-item-group color="primary">
                  <v-list-item
                    v-for="(item, index) in paymentList"
                    :key="index"
                    @click="changeRouter(item.value)"
                  >
                    <v-list-item-icon>
                      <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.title"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-menu>
          <!-- <v-menu offset-y left >
            <template v-slot:activator="{ on }">
              <v-btn text v-on="on">
                <v-icon class="d-md-none">post_add</v-icon>
                <span class="ml-2 d-none d-sm-flex">Invoice</span>
                <v-icon class="d-none d-sm-flex">expand_more</v-icon>
              </v-btn>
            </template>
            <v-card class="mx-auto pr-1 pl-1" max-width="300" tile>
              <v-list dense>

                <v-list-item-group color="primary">
                  <v-list-item
                    v-for="(item, index) in invoiceList"
                    :key="index"
                    @click="changeRouter(item.value)"
                  >
                    <v-list-item-icon>
                      <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.title"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-menu> -->
          <v-menu offset-y left v-if="uiAccept.moneyreport||uiAccept.paymentreport||uiAccept.balancereport||uiAccept.debitreport||uiAccept.customerreport||uiAccept.userreport||uiAccept.airlinereport">
            <template v-slot:activator="{ on }">
              <v-btn text v-on="on">
                <v-icon class="d-md-none">post_add</v-icon>
                <span class="ml-2 d-none d-sm-flex">Report</span>
                <v-icon class="d-none d-sm-flex">expand_more</v-icon>
              </v-btn>
            </template>
            <v-card class="mx-auto pr-1 pl-1" max-width="300" tile>
              <v-list dense>

                <v-list-item-group color="primary">
                  <v-list-item
                    v-for="(item, index) in report"
                    :key="index"
                    @click="changeRouter(item.value)"
                  >
                    <v-list-item-icon>
                      <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.title"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-menu>
          <v-menu offset-y left>
            <template v-slot:activator="{ on }">
              <v-btn text v-on="on">
                <v-icon>account_box</v-icon>
              </v-btn>
            </template>
            <v-card class="mx-auto pr-1 pl-1" max-width="300" tile>
              <v-list dense>
                <v-list-item-group color="primary">
                  <v-list-item
                    v-for="(item, index) in profileMenuItem"
                    :key="index"
                    @click="changeRouter(item.value)"
                  >
                    <v-list-item-icon>
                      <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.title"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-menu>
          <v-menu offset-y left v-if="uiAccept.setting||uiAccept.contacts||uiAccept.account||uiAccept.type||uiAccept.company||uiAccept.airline">
            <template v-slot:activator="{ on }">
              <v-btn text v-on="on" >
                <v-icon>settings</v-icon>
              </v-btn>
            </template>
            <v-card class="mx-auto pr-1 pl-1" max-width="300" tile>
              <v-list dense>
                <v-list-item-group color="primary">
                  <v-list-item
                    v-for="(item, index) in settingMenuItem"
                    :key="index"
                    @click="changeRouter(item.value)"
                  >
                    <v-list-item-icon>
                      <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.title"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-menu>
          
        </v-toolbar-items>
      </v-toolbar>
    </div>
    <v-content style="background-color:white">
      <v-progress-linear v-if="loading" fixed :indeterminate="loading" color="accent"></v-progress-linear>
      <router-view :hideToolbar="hideToolbar" />
    </v-content>
    <div>
      <v-dialog v-model="passwordModel" persistent max-width="290">
        <v-form ref="form" v-model="valid">
          <v-card>
            <v-card-title class="headline">Change Password?</v-card-title>
            <v-card-text class="pb-0">
              <v-text-field
                :append-icon="showOld ? 'visibility' : 'visibility_off'"
                :type="showOld ? 'text' : 'password'"
                @click:append="showOld = !showOld"
                :rules="passwordOldRule"
                v-model="password.old"
                label="Old Password"
                name="oldPassword"
                placeholder=" "
                outlined
                dense
              ></v-text-field>
              <v-text-field
                :append-icon="showNew ? 'visibility' : 'visibility_off'"
                :type="showNew ? 'text' : 'password'"
                @click:append="showNew = !showNew"
                :rules="passwordNewRule"
                v-model="password.new"
                label="New Password"
                name="newPassword"
                placeholder=" "
                outlined
                dense
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" :disabled="!valid" depressed @click="changePassword">Change</v-btn>
              <v-btn color="error" text @click="cancelChangePassword">Cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </div>
    <div>
      <v-dialog v-model="logoutModel" persistent max-width="350">
        <v-card>
          <v-card-title class="title">Are you sure want to Logout?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" depressed @click="logout">logout</v-btn>
            <v-btn color="error" text @click="logoutModel = false">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div>
           <v-overlay :value="loadingDialog" z-index="1000">
           <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
    </div>
  </v-app>
</template>
<script>
import accountUserService from "../src/service/UserAccountService";
import axios from "../src/config";
export default {
  name: "App",
  data: () => ({
    hiddenToolbar: false,
    invoiceList:[
    {
        title: "Invoice",
        icon: "fiber_new",
        value: "/invoice"
      },
      {
        title: "Invoice List",
        icon: "receipt",
        value: "/invlist"
      },
      {
        title: "Received",
        icon: "assignment_turned_in",
        value: "/invpayment"
      },
      {
        title: "Expenses List",
        icon: "receipt",
        value: "/expList"
      },
      {
        title: "Paid",
        icon: "assignment_turned_in",
        value: "/exppayment"
      },
    ],
    report: [],
    order: [],
    paymentList:[],
    incomeMeuns: [
      {
        title: "Payment",
        icon: "payment",
        value: "/payment"
      },
      // {
      //   title: "Income",
      //   icon: "local_atm",
      //   value: "/income"
      // },
      {
        title: "Payment",
        icon: "explicit",
        value: "/expense"
      }
    ],
    profileMenuItem: [
      {
        title: "Change Password",
        icon: "lock",
        value: "/change_password"
      },
      {
        title: "Logout",
        icon: "logout",
        value: "/logout"
      }
    ],
    settingMenuItem: [],
    passwordModel: false,
    password: {},
    valid: true,
    passwordOldRule: [v => !!v || "Old Password is required"],
    passwordNewRule: [v => !!v || "New Password is required"],
    showOld: false,
    showNew: false,
    loadingCount: 0,
    loading: false,
    profileName: "",
    userType: "",
    logoutModel: false,
    loadingDialog:false,
    uiAccept:{},
  }),
  computed: {
    isLogin: function() {
      let token = this.$store.state.userAccount.password;
      if (token == "") {
        return false;
      } else {
        axios.defaults.headers.common["Authorization"] = ` Bearer ${token}`;
        return true;
      }
    },
    role: function() {
      if (this.$store.state.userAccount == null) {
        return "";
      } else {
        return this.$store.state.userAccount.role;
      }
    }
  },
  watch: {
    role: function(v) {
      this.getUserType(v);
    }
  },
  mounted: function() {
    if (this.isLogin) {
      this.hiddenToolbar = false;
      this.userType = this.$store.state.userAccount.role;
      this.profileName = this.$store.state.userAccount.profileName;
      this.getUserType(this.userType);
    } else {
      this.hiddenToolbar = true;
      this.$router.push("/login").catch(() => {});
    }
    axios.interceptors.request.use(config => {
      this.loadingCount++;
      if (!this.loading) {
        this.loading = true;
        this.loadingDialog = true;
      }
      return config;
    });
    axios.interceptors.response.use(
      response => {
        this.loadingCount--;
        if (this.loadingCount == 0 && this.loading) {
          this.loading = false;
          this.loadingDialog = false;
        }
        return response;
      },
      error => {
        if (error.isAxiosError && error.message == "Network Error") {
          this.$swal("Check Internet Connection!", error.message, "error");
          this.loadingCount = 1;
          this.loading = false;
           this.loadingDialog = false;
        }
        if (error.response.data != null && error.response.data.status == 401) {
          this.$swal("Authentication Error", "Please Login again!", "error");
          //this.changeRouter("/logout");
          this.logout();
        }
        this.loadingCount--;
        if (this.loadingCount == 0 && this.loading) {
          this.loading = false;
           this.loadingDialog = false;
        }
        return Promise.reject(error);
      }
    );
  },
  methods: {
    uiAcceptMethod:function(){
      accountUserService
        .getUiByUserId()
        .then((response) => {
          this.uiAccept = Object.assign({},response);
          this.$store.commit("updateUiAccept", response);
          this.getUiData();
        })
        .catch((err) => {
          this.$swal("Failed", err.response.data.message, "error");
        });
    },
    getUiData: function() {
      this.order.splice(0, this.order.length);
      if(this.uiAccept.newvoucher){
        this.order.push({
        title: "New Voucher",
        icon: "fiber_new",
        value: "/voucher"
      });
      this.order.push({
        title: "TempVoucher",
        icon: "receipt",
        value: "/tempVoucher"
      });
      }
      if(this.uiAccept.voucherlist){
        this.order.push({
        title: "Voucher List",
        icon: "receipt",
        value: "/voucherList"
      });
      }
      if(this.uiAccept.voucherall){
        this.order.push({
        title: "Voucher All",
        icon: "receipt",
        value: "/voucherlistall"
      });
      }
      if(this.uiAccept.receivevoucher){
        this.order.push({
        title: "Receive Voucher",
        icon: "monetization_on",
        value: "/receiveVoucher"
      });
      }
      this.paymentList.splice(0);
      if(this.uiAccept.receipt){
        this.paymentList.push({
        title: "Receipt",
        icon: "payment",
        value: "/payment"
      });
      }
      if(this.uiAccept.commission){
        this.paymentList.push({
        title: "Commission",
        icon: "assignment_turned_in",
        value: "/commission"
      });
      }
      if(this.uiAccept.payment){
        this.paymentList.push({
        title: "Payment",
        icon: "account_balance_wallet",
        value: "/expense"
      });
      }
      
      this.report.splice(0);
      if(this.uiAccept.moneyreport){
        this.report.push({
        title: "Money Report",
        icon: "assignment_turned_in",
        value: "/billreport"
      });
      }
      if(this.uiAccept.paymentreport){
        this.report.push({
        title: "Payment Report",
        icon: "card_membership",
        value: "/expensereport"
      });
      }
      if(this.uiAccept.balancereport){
        this.report.push({
        title: "Balance Report",
        icon: "account_balance_wallet",
        value: "/balance"
      });
      }
      if(this.uiAccept.debitreport){
        this.report.push({
        title: "Debit/Credit Report",
        icon: "perm_identity",
        value: "/debit"
      });
      }
      if(this.uiAccept.customerreport){
        this.report.push({
        title: "Customer Report",
        icon: "book",
        value: "/customerReport"
      });
      }
      if(this.uiAccept.userreport){
        this.report.push({
        title: "User Report",
        icon: "perm_identity",
        value: "/rpuser"
      });
      }
      if(this.uiAccept.airlinereport){
        this.report.push({
        title: "Airline Report",
        icon: "flight",
        value: "/airlineReport"
      });
      }
      this.settingMenuItem.splice(0);
      if(this.uiAccept.setting){
        this.settingMenuItem.push({
            title: "Setting",
            icon: "settings_applications",
            value: "/setting"
          });
      }
      if(this.uiAccept.contacts){
        this.settingMenuItem.push({
            title: "Contacts",
            icon: "contact_phone",
            value: "/contacts"
          });
      }
      if(this.uiAccept.account){
        this.settingMenuItem.push({ title: "Account", icon: "person_add", value: "/account" });
      }
      if(this.uiAccept.type){
        this.settingMenuItem.push({ title: "Type", icon: "account_balance_wallet", value: "/cashtype" });
      }
      
      if(this.uiAccept.airline){
        this.settingMenuItem.push({
            title: "Airlines",
            icon: "flight",
            value: "/airline"
          });
      }
      if(this.uiAccept.company){
        this.settingMenuItem.push({
            title: "Company Profile",
            icon: "recent_actors",
            value: "/companyProfile"
          });
          this.settingMenuItem.push({
            title: "Ui Accept",
            icon: "settings_applications",
            value: "/ui"
          });
      }
    },
    getUserType: function(v) {
      this.uiAcceptMethod();
      
      // if (v == "ROLE_ADMIN") {
        // this.settingMenuItem.push(
        //   {
        //     title: "Setting",
        //     icon: "settings_applications",
        //     value: "/setting"
        //   },
        //   {
        //     title: "Contacts",
        //     icon: "contact_phone",
        //     value: "/contacts"
        //   },
        //   { title: "Account", icon: "person_add", value: "/account" },
        //   { title: "Type", icon: "account_balance_wallet", value: "/cashtype" },
        //   {
        //     title: "Company Profile",
        //     icon: "recent_actors",
        //     value: "/companyProfile"
        //   },
        //   {
        //     title: "Airlines",
        //     icon: "flight",
        //     value: "/airline"
        //   },
        //   {
        //     title: "Ui Accept",
        //     icon: "settings_applications",
        //     value: "/ui"
        //   }
        // );
      // } else {
      //   this.settingMenuItem.push(
      //     {
      //       title: "Setting",
      //       icon: "settings_applications",
      //       value: "/setting"
      //     },
      //     {
      //       title: "Contacts",
      //       icon: "contact_phone",
      //       value: "/contacts"
      //     },
      //     { title: "Type", icon: "account_balance_wallet", value: "/cashtype" },
      //     {
      //       title: "Airlines",
      //       icon: "flight",
      //       value: "/airline"
      //     }
      //   );
      // }
      
      // this.order.splice(0, this.order.length);
      // if (v == "ROLE_ADMIN" || v == "ROLE_ACCOUNTANT") {
      //   this.order.push(
      //     {
      //       title: "New Voucher",
      //       icon: "fiber_new",
      //       value: "/voucher"
      //     },
      //     {
      //       title: "TempVoucher",
      //       icon: "receipt",
      //       value: "/tempVoucher"
      //     },
      //     {
      //       title: "Voucher List",
      //       icon: "receipt",
      //       value: "/voucherList"
      //     },
      //     {
      //   title: "Voucher All",
      //   icon: "receipt",
      //   value: "/voucherlistall"
      // },
      //     {
      //       title: "Receive Voucher",
      //       icon: "monetization_on",
      //       value: "/receiveVoucher"
      //     }
      //   );
      // }else{
      //   this.order.push(
      //     {
      //       title: "New Voucher",
      //       icon: "fiber_new",
      //       value: "/voucher"
      //     },
      //     {
      //       title: "TempVoucher",
      //       icon: "receipt",
      //       value: "/tempVoucher"
      //     },
      //     {
      //       title: "Voucher List",
      //       icon: "receipt",
      //       value: "/voucherList"
      //     },{
      //   title: "Voucher All",
      //   icon: "receipt",
      //   value: "/voucherlistall"
      // },
      //   );
      // }
    },
    logout: function() {
      this.$store.commit("updateUserAccount", {
        userName: "",
        password: "",
        role: ""
      });
      this.changeRouter("/login");
      this.logoutModel = false;
    },
    cancelChangePassword: function() {
      this.passwordModel = false;
      this.password = {};
    },
    changePassword: function() {
      accountUserService
        .changePassword(this.password.old, this.password.new)
        .then(response => {
          if (response > 0) {
            this.$swal("Successful", "Change Password", "success");
            this.showPassword = false;
          } else {
            this.$swal("unSuccessful", "Change Password", "error");
          }
          this.password = {
            old: "",
            new: ""
          };
          this.passwordModel = false;
        });
    },
    changeRouter: function(path) {
      if (path == "/logout") {
        this.logoutModel = true;
      } else if (path == "/voucher") {
        let date = this.$moment().format("DD MMM YY HH:mm");
        this.$router
          .push({
            name: "voucher",
            params: {
              voucherId: date
            }
          })
          .catch(() => {});
      } else if (path == "/change_password") {
        this.passwordModel = true;
      } else {
        // console.log("hello12312");
        // console.log(path);
        this.$router
          .push({
            path: path
          })
          .catch(() => {});
      }

      // }
    },
    hideToolbar: function(hide) {
      this.hiddenToolbar = hide;
      this.profileName = this.$store.state.userAccount.profileName;
    }
  },
  components: {}
};
</script>

<style>
.v-stepper__header {
  height: 50px !important;
}
.v-stepper__step {
  padding: 0px 15px 0 15px !important;
}
.v-stepper__content {
  padding: 10px 10px 0px 10px !important;
}
.tbl-info table,
.tbl-info td,
.tbl-info th {
  padding: 4px;
}
.tbl-info thead th,
.tbl-info thead td {
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 3px;
  padding-right: 3px;
  border: 1px solid #e0e0e0;
  background: #4caf50 !important;
  text-align: center !important;
  color: white !important;
}

.tbl-info tbody th,
.tbl-info tbody td {
  font-size: 13px;
  padding-left: 3px;
  border: 1px solid #e7e7e7;
  padding-right: 1px;
}
.color1 {
  background: #c8e6c9;
}
.color2 {
  background: #66bb6a;
}
.color3 {
  background: #388e3c;
}
.color4 {
  background: #b9f6ca;
}

html::-webkit-scrollbar {
 width: 0.2rem;
}

html::-webkit-scrollbar-track {
  box-shadow: inset 1px 0 5px #69BC6C;
}
 
html::-webkit-scrollbar-thumb {
  background-color: #28a12c;
  outline: 1px solid slategrey;
}

div::-webkit-scrollbar {
  width: 0.5rem;
}
 
div::-webkit-scrollbar-track {
  box-shadow: inset 1px 0 5px #69BC6C;
}
 
div::-webkit-scrollbar-thumb {
  background-color: #28a12c;
  outline: 1px solid slategrey;
}
</style>
